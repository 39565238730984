import {
  IonButtons,
  IonButton,
  IonContent,
  IonHeader,
  IonMenuButton,
  IonPage,
  IonTitle,
  IonToolbar,
} from "@ionic/react";
import React from "react";
import { useHistory } from "react-router-dom";
import { useParams } from "react-router";
import { logout } from "../components/VerifiToken";

import ExploreContainer from "../components/ExploreContainer";

import "./Page.css";

const Page: React.FC = () => {
  const { name, id } = useParams<{ name: string; id: string }>();
  const history = useHistory();

  function selectOpccion(datos: string) {
    logout();
    history.push("/" + datos);
  }

  return (
    <IonPage>
      <IonHeader>
        <IonToolbar>
          <IonButtons slot="start">
            <IonMenuButton />
          </IonButtons>
          <IonTitle>
            {name}
            <IonButton color="primary" onClick={() => selectOpccion("login")}>
              Login
            </IonButton>
            <IonButton color="primary" onClick={() => selectOpccion("login")}>
              Logout
            </IonButton>
          </IonTitle>
        </IonToolbar>
      </IonHeader>
      <IonContent fullscreen>
        <IonHeader collapse="condense">
          <IonToolbar>
            <IonTitle size="large">
              {name} / {id}
            </IonTitle>
          </IonToolbar>
        </IonHeader>
        <ExploreContainer name={name} />
      </IonContent>
    </IonPage>
  );
};

export default Page;
