import Page from "./pages/Page";
import Login from "./pages/Login";
import PageNotFound from "./pages/PageNotFound";

import React from "react";
import { IonApp, IonRouterOutlet, IonSplitPane } from "@ionic/react";
import { IonReactRouter } from "@ionic/react-router";
import { Route, Redirect } from "react-router-dom";

/* Core CSS required for Ionic components to work properly */
import "@ionic/react/css/core.css";

/* Basic CSS for apps built with Ionic */
import "@ionic/react/css/normalize.css";
import "@ionic/react/css/structure.css";
import "@ionic/react/css/typography.css";

/* Optional CSS utils that can be commented out */
import "@ionic/react/css/padding.css";
import "@ionic/react/css/float-elements.css";
import "@ionic/react/css/text-alignment.css";
import "@ionic/react/css/text-transformation.css";
import "@ionic/react/css/flex-utils.css";
import "@ionic/react/css/display.css";

/* Theme variables */
import "./theme/variables.css";
import "./theme/acciones.css";
import { DataProvider } from "./components/DataContext";
//import Menu from "./components/Menu";
import PrivateRoute from "./components/PrivateRouter";
import PublicRoute from "./components/PublicRouter";

const App: React.FC = () => {
  return (
    <IonApp>
      <DataProvider>
        <IonReactRouter>
          <IonSplitPane contentId="main">
            <IonRouterOutlet id="main">
              <PublicRoute restricted={false} path="/" exact>
                <Redirect to="/login" />
              </PublicRoute>

              <PublicRoute restricted={true} path="/login" exact>
                <Login />
              </PublicRoute>

              <PrivateRoute path="/page/:name" component={Page} exact>
                <Page />
              </PrivateRoute>

              <Route component={PageNotFound} />
            </IonRouterOutlet>
          </IonSplitPane>
        </IonReactRouter>
      </DataProvider>
    </IonApp>
  );
};

export default App;
