import {
  IonButtons,
  IonHeader,
  IonPage,
  IonTitle,
  IonToolbar,
} from "@ionic/react";
import React from "react";
import { useHistory } from "react-router-dom";

const PageNotFound: React.FC = () => {
  const history = useHistory();

  setTimeout(() => {
    history.push("/page/Inbox");
  }, 5000);

  return (
    <IonPage>
      <IonHeader>
        <IonToolbar>
          <IonButtons slot="start" />
          <IonTitle>
            Uy, llegaste a un mundo desconocido. Mejor regresa al inicio.
          </IonTitle>
        </IonToolbar>
      </IonHeader>
    </IonPage>
  );
};

export default PageNotFound;
