import React from "react";
import { Route, Redirect, RouteProps } from "react-router-dom";
import { isLogin } from "./VerifiToken";

interface PrivateRouteProps extends RouteProps {
  children: React.ReactNode;
}

const PrivateRoute: React.FC<PrivateRouteProps> = ({ children, ...rest }) => {
  return (
    <Route {...rest}> {isLogin() ? children : <Redirect to="/login" />} </Route>
  );
};

export default PrivateRoute;
