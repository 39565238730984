import React from "react";
import { Route, Redirect, RouteProps } from "react-router-dom";
import { isLogin } from "./VerifiToken";

interface PublicRouteProps extends RouteProps {
  children: React.ReactNode;
  restricted: boolean;
}

const PublicRoute: React.FC<PublicRouteProps> = ({
  children,
  restricted,
  ...rest
}) => {
  return (
    <Route {...rest}>
      {isLogin() && restricted ? <Redirect to="/page/Inbox4" /> : children}
    </Route>
  );
};

export default PublicRoute;
