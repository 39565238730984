// DataContext.tsx
import React, { useState } from "react";

interface IState {
  User: any;
  updateUser: (datos: any) => void;
}

// create the context
export const DataContext = React.createContext<IState | undefined>(undefined);

export const DataProvider: React.FC = ({ children }) => {
  const [User, setUser] = useState<any>(null);

  const updateUser = (datos: any) => {
    setUser(datos);
  };

  let state: IState = {
    User,
    updateUser,
  };

  // wrap the app in the provider with the initialized context
  return <DataContext.Provider value={state}>{children}</DataContext.Provider>;
};

export default DataContext;

export const useDataProvider = () =>
  React.useContext<IState | undefined>(DataContext)!;
