import {
  IonAvatar,
  IonButton,
  IonCol,
  IonContent,
  IonIcon,
  IonInput,
  IonItem,
  IonLabel,
  IonLoading,
  IonPage,
  IonRow,
  IonToast,
} from "@ionic/react";
import { upToken, firstUcase } from "../components/VerifiToken";
import { useHistory } from "react-router-dom";
import { eye, eyeOff } from "ionicons/icons";
import React, { useState } from "react";
import jwt_decode from "jwt-decode";

import { useDataProvider } from "../components/DataContext";

import "./Login.css";

const Login: React.FC = () => {
  const { updateUser } = useDataProvider();
  const history = useHistory();
  const [isLoading, setIsLoading] = useState(false);
  const [activeLogin, setActiveLogin] = useState(false);

  const [user, setUser] = useState("");
  const [password, setPassword] = useState("");
  const [nombreUser, setNombreUser] = useState("");

  const [imagenAvatar, setImagenAvatar] = useState(
    "https://www.nicepng.com/png/detail/202-2022264_usuario-annimo-usuario-annimo-user-icon-png-transparent.png"
  );
  const [showPassword, setShowPassword] = useState(eye);
  const [typePassword, setTypePassword] = useState<"text" | "password">(
    "password"
  );

  const [showToast, setShowToast] = useState(false);
  const [menssageToast, setMenssageToast] = useState("");
  const [colorToast, setColorToast] = useState("success");

  function activePassword() {
    if (showPassword === eye) {
      setTypePassword("text");
      setShowPassword(eyeOff);
    } else {
      setTypePassword("password");
      setShowPassword(eye);
    }
  }

  function selectOpccion(datos: string) {
    history.push("/" + datos);
  }

  async function Login() {
    setIsLoading(true);
    setActiveLogin(true);
    const datos = { user: user, password: password };

    if (user !== "" && password !== "") {
      const requestOptions = {
        method: "POST",
        body: JSON.stringify(datos),
        headers: { "Content-Type": "application/json" },
      };

      try {
        const response = await fetch(
          "https://winningwithenglish.com/apis/jwt/autentica",
          requestOptions
        );

        const dato = await response.json();

        if (dato.token === undefined) {
          setActiveLogin(false);
          setMenssageToast("Incorrect user or password.");
          setColorToast("warning");
          setShowToast(true);
        } else {
          const tokenDecoded: any = jwt_decode(dato.token);
          const nombre = firstUcase(tokenDecoded.data.nombre);
          setImagenAvatar(dato.avatar);
          setNombreUser(nombre);

          upToken(dato.token, dato.avatar);

          updateUser(tokenDecoded);
          history.push("/page/Inbox");
        }
      } catch (e) {
        setActiveLogin(false);
        setMenssageToast("Incorrect user or password.");
        setColorToast("warning");
        setShowToast(true);
      }
    } else {
      setActiveLogin(false);
      if (user === "" || user === "") {
        setMenssageToast("add user");
        setColorToast("warning");
        setShowToast(true);
      } else {
        setMenssageToast("add password");
        setColorToast("warning");
        setShowToast(true);
      }
    }
    setIsLoading(false);
  }

  return (
    <IonPage>
      <IonContent fullscreen className="content-login">
        <div className="full-vh">
          <div className="area-login">
            <h1 className="text-center">Welcome {nombreUser} </h1>
            <IonRow>
              <IonCol size="4" offset="4">
                <IonAvatar>
                  <img
                    className="img-thumbnail"
                    src={imagenAvatar}
                    alt="Avatar"
                  />
                </IonAvatar>
              </IonCol>
            </IonRow>
            <IonRow>
              <IonCol>
                <IonItem>
                  <IonLabel position="floating">User</IonLabel>
                  <IonInput
                    value={user}
                    onIonChange={(e) => setUser(e.detail.value!)}
                  ></IonInput>
                </IonItem>
              </IonCol>
            </IonRow>
            <IonRow>
              <IonCol>
                <IonItem>
                  <IonLabel position="floating">Password</IonLabel>
                  <IonIcon
                    icon={showPassword}
                    slot="end"
                    onClick={() => activePassword()}
                  />
                  <IonInput
                    type={typePassword}
                    value={password}
                    onIonChange={(e) => setPassword(e.detail.value!)}
                  ></IonInput>
                </IonItem>
              </IonCol>
            </IonRow>
            <IonRow>
              <IonCol size="12">
                <IonButton
                  disabled={activeLogin}
                  color="primary"
                  expand="block"
                  onClick={() => Login()}
                >
                  Login
                </IonButton>
              </IonCol>
              <IonCol size="6">
                <IonButton
                  className="s-text-s"
                  size="small"
                  expand="block"
                  fill="outline"
                  onClick={() => selectOpccion("register")}
                >
                  Register
                </IonButton>
              </IonCol>
              <IonCol size="6">
                <IonButton
                  className="s-text-s"
                  size="small"
                  expand="block"
                  fill="outline"
                  onClick={() => selectOpccion("recovery")}
                >
                  recover password?
                </IonButton>
              </IonCol>
            </IonRow>
          </div>
        </div>
        <IonLoading cssClass="" isOpen={isLoading} message={"Please wait..."} />
        <IonToast
          isOpen={showToast}
          onDidDismiss={() => setShowToast(false)}
          message={menssageToast}
          color={colorToast}
          duration={2000}
        />
      </IonContent>
    </IonPage>
  );
};

export default Login;
