import jwt_decode from "jwt-decode";

const TOKEN_KEY = "_cap_token";
const AVATAR_KEY = "_cap_avatar";

export const upToken = (Token: string, Avatar: string) => {
  localStorage.setItem(TOKEN_KEY, Token);
  localStorage.setItem(AVATAR_KEY, Avatar);
};

export const logout = () => {
  localStorage.removeItem(TOKEN_KEY);
  localStorage.removeItem(AVATAR_KEY);
};

export const isLogin = () => {
  if (localStorage.getItem(TOKEN_KEY)) {
    const Token = localStorage.getItem(TOKEN_KEY) || "";
    const tokenDecoded: any = jwt_decode(Token);
    const now = Date.now().valueOf() / 1000;
    return tokenDecoded.exp > now;
  }

  return false;
};

export const isinforma = () => {
  if (localStorage.getItem(TOKEN_KEY)) {
    const Token = localStorage.getItem(TOKEN_KEY) || "";
    const tokenDecoded: any = jwt_decode(Token);
    return tokenDecoded.data;
  }
  return null;
};

export const firstUcase = (dato: string) => {
  dato = dato.toLowerCase();
  return dato.charAt(0).toUpperCase() + dato.slice(1);
};
